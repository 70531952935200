import { FlightLegDecision, FlightLegDecisionEnum, FlightLegStatus, SchedulingFlightLeg } from "@soar/shared/types"
import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"

dayjs.extend(isBetween)

export const FlightLegStatusConfig: Record<
  FlightLegStatus,
  {
    label: string
    color: string
    icon: string
    darkForeground: boolean
  }
> = {
  created: {
    label: "Created",
    color: "#495057",
    icon: "pending_actions",
    darkForeground: false,
  },
  on_hold: {
    label: "On hold",
    color: "#F95349",
    icon: "block",
    darkForeground: false,
  },
  approved: {
    label: "Released",
    color: "#2072fA",
    icon: "task_alt",
    darkForeground: false,
  },
  awaiting_approval: {
    label: "In review",
    color: "#F88113",
    icon: "policy",
    darkForeground: false,
  },
}

export const FlightLegDecisionConfig: Record<
  FlightLegDecision,
  {
    action: string
    cta: string
    status: string
  }
> = {
  [FlightLegDecisionEnum.enum.approve]: {
    action: "released",
    cta: "Release",
    status: "released",
  },
  [FlightLegDecisionEnum.enum.deny]: {
    action: "put on hold",
    cta: "Hold",
    status: "On hold",
  },
}

export function findExistingFlightLegMatch(
  flightDetails: {
    departureAirport?: string
    destinationAirport?: string
    aircraftTailNumber?: string
    departureDate?: Date | string
  },
  existingFlightLegs: SchedulingFlightLeg[],
  options?: {
    override?: (currentFlightLeg: SchedulingFlightLeg) => boolean
    additional?: (currentFlightLeg: SchedulingFlightLeg) => boolean
  },
) {
  if (flightDetails.departureDate == null) {
    return undefined
  }
  const departureDate = dayjs(flightDetails.departureDate)
  const bufferValue = 5
  const bufferUnit = "seconds" as const

  const departureDateBefore = departureDate.add(-1 * bufferValue, bufferUnit)
  const departureDateAfter = departureDate.add(bufferValue, bufferUnit)
  return existingFlightLegs.find((existingFlightLeg) => {
    const existingDepartureDate = dayjs(existingFlightLeg.departAt)

    const passesOverride = options?.override?.(existingFlightLeg) ?? false
    const passesAdditional = options?.additional == null || options.additional(existingFlightLeg)
    const passesCore =
      existingFlightLeg.departureAirport === flightDetails.departureAirport &&
      existingFlightLeg.destinationAirport === flightDetails.destinationAirport &&
      existingFlightLeg.aircraftTailNumber.toUpperCase().trim() === flightDetails.aircraftTailNumber?.toUpperCase().trim() &&
      existingDepartureDate.isBetween(departureDateBefore, departureDateAfter)
    return passesOverride || (passesAdditional && passesCore)
  })
}
